import banquetEventIcon from '../assets/images/icons/event-type-icons/banquet.svg'
import boardroomEventIcon from '../assets/images/icons/event-type-icons/boardroom.svg'
import resceptionEventIcon from '../assets/images/icons/event-type-icons/reception.svg'
import schoolRoomEventIcon from '../assets/images/icons/event-type-icons/school-room.svg'
import theatreEventIcon from '../assets/images/icons/event-type-icons/theatre.svg'
import uShapedEventIcon from '../assets/images/icons/event-type-icons/u-shaped.svg'

import {
    FeatureType_PEnum,
    MediaType_PEnum,
    PricingConfigType_PEnum,
    ProfileCategory_PEnum,
    ProfileItemType_PEnum,
    ProfileTier_DB_PEnum,
    ProfileTier_UI_PEnum,
    ProfileType_PEnum,
    accommodationCategoryList, activityCategoryList,
    attractionAndLandMarksCategoryList, destinationCategoryList, foodAndBeverageCategoryList,
    getAllCategoriesExcept,
    informationAndServicesCategoryList,
    infrastructureCategoryList,
    venueCategoryList
} from '../configs/pseudoEnums'

export const tierArray = [
    {
        name: ProfileTier_UI_PEnum.Core, 
        description: "Limited content options, functionality, and distribution options. This offers everything you need to quickly start building your online presence.",
        price: "0",
        subName: "Free",
        subDescription: "Publish for free"
    },
    {
        name: ProfileTier_UI_PEnum.Professional,
        description: "This offers a wide set of customisation, branding, content, functionality, and distribution options.",
        price: "XX",
        subName: "Paid",
        subDescription: "Publish at an incurred cost"
    }
    //{name: "Premium", description: "This offers a wide set of customisation options, branding, content, functionality, and distribution options.", price: "XXX"}
]

export const tagsArray = [ 
    {label: "Apartment", value: "apartment", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]}, 
    {label: "Backpacker", value: "backpacker", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "BnB", value: "bnb", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Boat", value: "boat", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Boutique", value: "boutique", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Bungalow", value: "bungalow", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Camper", value: "camper", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Camps", value: "camps", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Campsite", value: "campsite", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Capsule", value: "capsule", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Casino", value: "casino", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Chalet", value: "chalet", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Caravan Park", value: "caravan park", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Condo", value: "condo", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Country House", value: "country house", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Cruise Ship", value: "cruise ship", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Eco Camps", value: "eco camps", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Flight", value: "flight", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Guest House", value: "guest house", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Holiday Village", value: "holiday village", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Homestay", value: "homestay", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Horseback", value: "horseback", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Hostel", value: "hostel", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Hotel", value: "hotel", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Houseboat", value: "houseboat", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Inn", value: "inn", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Lodge", value: "lodge", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Luxury", value: "luxury", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Luxury Tented Camp", value: "luxury tented camp", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Luxury Yacht", value: "luxury yacht", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Mobile Safari", value: "mobile safari", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Motel", value: "motel", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Permanent Tented", value: "permanent tented", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Rail", value: "rail", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Resort", value: "resort", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Rest Camps", value: "rest camps", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "River Cruise", value: "river cruise", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Riverboat", value: "riverboat", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "RV", value: "rv", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.vehicle, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Safari", value: "safari", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Serviced Apartment", value: "serviced apartment", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Short Term Rental", value: "short term rental", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Spa", value: "spa", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Suite", value: "suite", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Tented Camps", value: "tented camps", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Train", value: "train", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Vehicle", value: "vehicle", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.vehicle, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Villa", value: "villa", profileCategoryType:[...accommodationCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},

    {label: "Apartment / Villa", value:"apartmentVilla", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Bed & breakfast", value:"bedAndBreakfast", profileCategoryType:[ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat, ...venueCategoryList], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Boutique hotel", value:"boutiqueHotel", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Mobile camp", value:"mobileCamp", profileCategoryType:[ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Nature", value:"nature", profileCategoryType:[ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Safari lodge", value:"safariLodge", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Private Safari Lodge", value:"privateSafariLodge", profileCategoryType:[...accommodationCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    {label: "Self-catering", value:"selfCatering", profileCategoryType:[ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},

    {label: "Abseiling", value: "abseiling", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Adventure", value: "adventure", profileCategoryType:[...activityCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Beach", value: "beach", profileCategoryType:[...activityCategoryList, ...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Boat Cruise", value: "boat cruise", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Bowling", value: "bowling", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Bungi Jumping", value: "bungi jumping", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Canoe Trips", value: "canoe trips", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Deep Sea Fishing", value: "deep sea fishing", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Fitness Centre", value: "fitness centre", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Fighter Jets", value: "fighter jets", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Fly Fishing", value: "fly fishing", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Four Wheel Drive", value: "Four Wheel Drive", profileCategoryType:[ProfileCategory_PEnum.vehicle], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Game Drive", value: "game drive", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Golf", value: "golf", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Health Spa", value: "health spa", profileCategoryType:[...activityCategoryList, ...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Horse riding", value: "horse riding", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Hot Air Balloon", value: "hot air balloon", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Ice Skating", value: "ice skating", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Jet Skiing", value: "jet skiing", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Kayaking", value: "kayaking", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Kite Boarding", value: "kite boarding", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Kloofing", value: "kloofing", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Learning to Surf", value: "learning to surf", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Leisure", value: "leisure", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Mountain Bike", value: "mountain bike", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Outdoor", value: "outdoor", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Paragliding", value: "paragliding", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Parasailing", value: "parasailing", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Photo Opportunity", value: "photo opportunity", profileCategoryType:[...activityCategoryList, ...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Picnic", value: "picnic", profileCategoryType:[...activityCategoryList, ...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Polo", value: "polo", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Power Boating", value: "power boating", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Quad Biking", value: "quad biking", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Rock Climbing", value: "rock climbing", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Running", value: "running", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Hot Air Ballooning", value: "hot air ballooning", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Sailing", value: "sailing", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Sandboarding", value: "sandboarding", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Scenic Walk", value: "scenic walk", profileCategoryType:[...activityCategoryList, ...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Scuba Diving", value: "scuba diving", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Shark Diving", value: "shark diving", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Sightseeing", value: "sightseeing", profileCategoryType:[...activityCategoryList, ...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Sky Diving", value: "sky diving", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Snorkeling", value: "snorkeling", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Sport", value: "sport", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Squash", value: "squash", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Stadium", value: "stadium", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Stand Up Paddleboarding", value: "stand up paddleboarding", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Sundowner", value: "sundowner", profileCategoryType:[...activityCategoryList, ...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Surfing", value: "surfing", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Swimming", value: "swimming", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Tennis", value: "tennis", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Tourist Information", value: "tourist information", profileCategoryType:[...activityCategoryList, ...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Tree-top Tours", value: "tree-top tours", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Walk / Hike", value: "walk / hike", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Water Skiing", value: "water skiing", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Watersport", value: "watersport", profileCategoryType:[...activityCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "White Water Rafting", value: "white water rafting", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Windsurfing", value: "windsurfing", profileCategoryType:[...activityCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Wine Estate", value: "wine estate", profileCategoryType:[...activityCategoryList, ...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Wine Tasting", value: "wine tasting", profileCategoryType:[...activityCategoryList, ...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Other", value: "other", profileCategoryType:[...activityCategoryList, ...attractionAndLandMarksCategoryList, ...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.attraction, ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},

    {label: "African", value: "african", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Asian", value: "asian", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Contemporary", value: "contemporary", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Deli", value: "deli", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Fast foods", value: "fast foods", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Italian", value: "italian", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Fine dining", value: "fine dining", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Fusion", value: "fusion", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Grill", value: "grill", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Indian", value: "indian", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Local", value: "local", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Portuguese", value: "portuguese", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Seafood", value: "seafood", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    {label: "Sushi", value: "sushi", profileCategoryType:[...foodAndBeverageCategoryList], profileTypes: [ProfileType_PEnum.restaurant, ProfileType_PEnum.other]},
    
    {label: "Amusement Park", value: "amusement park", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Aquarium / Oceanarium", value: "aquarium / oceanarium", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Art", value: "art", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Jewellery", value: "jewellery", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Arts & Crafts", value: "arts & crafts", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Baboon Interaction", value: "baboon interaction", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Bar", value: "bar", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Theatre", value: "theatre", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Battlefield", value: "battlefield", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    
    {label: "Big 5 Game Reserve", value: "big 5 game reserve", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Game Reserve", value: "game reserve", profileCategoryType:[...attractionAndLandMarksCategoryList, ...accommodationCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.accommodation, ProfileType_PEnum.other]},
    
    {label: "Birding Hot Spot", value: "birding hot spot", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Birding", value: "birding", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Boat Cruise", value: "boat cruise", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Botanical Garden", value: "botanical garden", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Caves", value: "caves", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Clothing", value: "clothing", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Dolphin Watching", value: "dolphin watching", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Elephant Interaction", value: "elephant interaction", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Estate Agent", value: "estate agent", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Farm / Safari Park", value: "farm / safari park", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Gambling", value: "gambling", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    
    {label: "Guided Tour", value: "guided tour", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Guided Walk", value: "guided walk", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Hstorical Site", value: "hstorical site", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Jewelery", value: "jewelery", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Live Music", value: "live music", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Monument / Memorial", value: "monument / memorial", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Movies", value: "movies", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Museum", value: "museum", profileCategoryType:[...attractionAndLandMarksCategoryList, ...venueCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Historical Site", value: "historical site", profileCategoryType:[...attractionAndLandMarksCategoryList, ...venueCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Cultural Site", value: "cultural site", profileCategoryType:[...attractionAndLandMarksCategoryList, ...venueCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Natural Beauty", value: "natural beauty", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Nature Reserve", value: "nature reserve", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Nightclub", value: "nightclub", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Outdoor Market", value: "outdoor market", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Place of Interest", value: "place of interest", profileCategoryType:[...attractionAndLandMarksCategoryList, ...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.information, ProfileType_PEnum.other]},
    {label: "Place of Worship", value: "place of worship", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other] },
    {label: "Pub", value: "pub", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Rock Art", value: "rock art", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Shopping Hot Spot", value: "shopping hot spot", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Shopping Mall", value: "shopping mall", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Star Gazing", value: "star gazing", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Theater", value: "theater", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Township Tour", value: "township tour", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Viewing Area", value: "viewing area", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Whale Watching", value: "whale watching", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Wildlife Interaction", value: "wildlife interaction", profileCategoryType:[...attractionAndLandMarksCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.other]},
    {label: "Wildlife Sanctuary", value: "wildlife sanctuary", profileCategoryType:[...attractionAndLandMarksCategoryList, ...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.attraction, ProfileType_PEnum.information, ProfileType_PEnum.other]},

    {label: "Art Gallery and Picture Framing", value:"art gallery and picture framing", profileCategoryType:[...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },
    {label: "Company", value:"company", profileCategoryType:[...informationAndServicesCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },
    {label: "Day tour", value:"day tour", profileCategoryType:[...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },
    {label: "Tailor Made Tour", value:"tailor made tour", profileCategoryType:[...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },
    {label: "Greeting", value:"greeting", profileCategoryType:[...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },
    {label: "Guide", value:"guide", profileCategoryType:[...informationAndServicesCategoryList, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },
    {label: "Hike/Walking", value:"hike/walking", profileCategoryType:[...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },
    {label: "Monument/Memorial", value:"monument/memorial", profileCategoryType:[...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },
    {label: "Property", value:"property", profileCategoryType:[...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },
    {label: "Car Rental", value:"car rental", profileCategoryType:[...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.infrastructure, ProfileType_PEnum.other, ProfileType_PEnum.carRental] },
    {label: "Charter Flight", value:"charter flight", profileCategoryType:[...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },
    {label: "Tour", value:"tour", profileCategoryType:[...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },
    {label: "Tourist Activity", value:"tourist activity", profileCategoryType:[...informationAndServicesCategoryList], profileTypes: [ProfileType_PEnum.information, ProfileType_PEnum.other] },

    {label: "International airport", value: "International airport", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Airport", value: "Airport", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Airfield", value: "Airfield", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Harbour", value: "Harbour", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Train station", value: "Train station", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Dummy point", value: "Dummy point", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Gate", value: "Gate", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Route point", value: "Route point", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Bus station", value: "Bus station", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Bridge", value: "Bridge", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Helipad", value: "Helipad", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Petrol station", value: "Petrol station", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Embassy", value: "Embassy", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other] },
    {label: "Polygon", value: "Polygon", profileCategoryType: [...infrastructureCategoryList], profileTypes: [ProfileType_PEnum.infrastructure, ProfileType_PEnum.other]  },
    {label: "Arena auditorium", value: "Arena auditorium", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Airport hotel", value: "Airport hotel", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other, ProfileType_PEnum.venues]},
    {label: "Bush venue", value: "Bush venue", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Church / Chapel", value: "Church / Chapel", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other, ProfileType_PEnum.venues]},
    {label: "City conference venue", value: "City conference venue", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "City hotel", value: "City hotel", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.venues, ProfileType_PEnum.other]},
    {label: "Civic centre", value: "Civic centre", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Coastal venue", value: "Coastal venue", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.venues, ProfileType_PEnum.other]},
    {label: "Computer training facility", value: "Computer training facility", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Corporate facility", value: "Corporate facility", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Country club", value: "Country club", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Cultural village", value: "Cultural village", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Day conference venue", value: "Day conference venue", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Educational establishment", value: "Educational establishment", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.venues, ProfileType_PEnum.other]},
    {label: "Expo centre", value: "Expo centre", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Boats / Cruiser", value: "boats / cruiser", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.venues, ProfileType_PEnum.other]},

    {label: "Farm venue", value: "Farm venue", profileCategoryType:[...venueCategoryList, ProfileCategory_PEnum.other], profileTypes: [ProfileType_PEnum.other]},
    {label: "Game lodge", value: "Game lodge", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Guest houses & lodges", value: "Guest houses and lodges", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Multi-purpose function", value: "Multi-purpose function", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.venues, ProfileType_PEnum.other]},
    {label: "National park", value: "National park", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Resort hotel", value: "Resort hotel", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.venues, ProfileType_PEnum.other]},
    {label: "Restaurant", value: "Restaurant", profileCategoryType:[...venueCategoryList, ProfileCategory_PEnum.leisure], profileTypes: [ProfileType_PEnum.activity, ProfileType_PEnum.other]},
    {label: "Self catering sports club", value: "Self catering sports club", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Stadium", value: "stadium", profileCategoryType:[...venueCategoryList, ProfileCategory_PEnum.other], profileTypes: [ProfileType_PEnum.venues, ProfileType_PEnum.other]},
    {label: "Team building venue", value: "Team building venue", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Tented or outdoor camp", value: "Tented or outdoor camp", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Theatre training facility", value: "Theatre training facility", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.venues, ProfileType_PEnum.other]},
    {label: "Venue municipal hall", value: "Venue municipal hall", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.other]},
    {label: "Wedding venue", value: "Wedding venue", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.venues, ProfileType_PEnum.other]},
    {label: "Country venue", value: "country venue", profileCategoryType:[...venueCategoryList], profileTypes: [ProfileType_PEnum.venues, ProfileType_PEnum.other]},
    
    {label:  "Country", value: "country", profileCategoryType:[...destinationCategoryList], profileTypes:[ProfileType_PEnum.destination] },
    {label:  "Province", value: "province", profileCategoryType:[...destinationCategoryList], profileTypes:[ProfileType_PEnum.destination] },
    {label:  "State", value: "state", profileCategoryType:[...destinationCategoryList], profileTypes:[ProfileType_PEnum.destination] },
    {label:  "National park", value: "national park", profileCategoryType:[...destinationCategoryList], profileTypes:[ProfileType_PEnum.destination] },
    {label:  "Reserve", value: "reserve", profileCategoryType:[...destinationCategoryList], profileTypes:[ProfileType_PEnum.destination] },
    {label:  "Conservation", value: "conservation", profileCategoryType:[...destinationCategoryList], profileTypes:[ProfileType_PEnum.destination] },
    {label:  "Town", value: "town", profileCategoryType:[...destinationCategoryList], profileTypes:[ProfileType_PEnum.destination] },
    {label:  "City", value: "city", profileCategoryType:[...destinationCategoryList], profileTypes:[ProfileType_PEnum.destination] },
    {label:  "DMO", value: "dmo", profileCategoryType:[...destinationCategoryList], profileTypes:[ProfileType_PEnum.destination] },
    {label:  "Tourism department", value: "tourism department", profileCategoryType:[...destinationCategoryList], profileTypes:[ProfileType_PEnum.destination] }    
]

export const typesArray = [
    {name: "Stays", description: "Hotel, B&B, Boat, Lodge", value: ProfileType_PEnum.accommodation, cardId:"typeStaysChoiceCard"},
    {name: "Things to do", description: "Activities, Guides, Excursions", value: ProfileType_PEnum.activity, cardId:"typeThingToDoChoiceCard"},
    {name: "Venues", description: "Conference venues, Expo centres, Stadiums", value: ProfileType_PEnum.venues, cardId:"typeVenuesChoiceCard"},
    {name: "Food & Beverage", description: "Dining, Cafe, Bakery, Food market", value: ProfileType_PEnum.restaurant, cardId:"typeFoodBeverageChoiceCard"},
    {name: "Destination", description: "TFCA, Reserve, Conservation", value: ProfileType_PEnum.destination, cardId:"typeDestinationsChoiceCard"},
    {name: "Attractions & Landmarks", description: "Museums, Landmarks, Attractions", value: ProfileType_PEnum.attraction, cardId:"typeAttractionsLandmarksChoiceCard"},
    { name: "Information / Services", description: "Meet and Greet, Information, Person", value: ProfileType_PEnum.information, cardId: "typeServicesChoiceCard" },
    { name: "Infrastructure", description: "Harbours, Airports, Train Stations, Car Rental", value: ProfileType_PEnum.infrastructure, cardId: "typeInfrastructureChoiceCard" },
    { name: "Other", description: "Profiles that are not included in the types listed above", value: ProfileType_PEnum.other, cardId: "typeOtherChoiceCard" }
]

export const profileEditorMenuItems = [    
    {
        name: "General", 
        route: "general",
        id: "generalTab", 
        children: [
            {
                name: "Details", 
                route: "details",
                id: "generalDetailsButton"
            }, 
            {
                name: "Fast facts", 
                route:"fastfacts",
                id: "generalFastFactsButton"
            }, 
            {
                name: "Travel information", 
                route: "travelinformation",
                id: "generalTravelInformationButton",
                profileTypeRestrictedFrom: [
                    ProfileType_PEnum.information, ProfileType_PEnum.infrastructure, ProfileType_PEnum.other,
                    ProfileType_PEnum.accommodation, ProfileType_PEnum.activity, ProfileType_PEnum.attraction, ProfileType_PEnum.restaurant,
                    ProfileType_PEnum.variant, ProfileType_PEnum.venues
                ]
            },
            {
                name: "Facilities", 
                route: "facilities",
                id: "generalFacilitiesButton",
                profileTypeRestrictedFrom: [ProfileType_PEnum.information, ProfileType_PEnum.infrastructure, ProfileType_PEnum.destination, ProfileType_PEnum.other]
            },
            {
                //Cant import the utils function (it messes with the wizard category for the types)
                hideChild: (isWetuUser, profile) => (
                        (profile.category === ProfileCategory_PEnum.area 
                        || (profile.type === ProfileType_PEnum.destination && profile.category === ProfileCategory_PEnum.other)
                        || profile.type === ProfileType_PEnum.infrastructure)
                    && isWetuUser),
                name: "Contact details", 
                route: "contactdetails",
                id: "generalContactDetailsButton"
            }
        ]
    },
    {
        name: "Location", 
        route: "location", 
        id: "locationTab",
        profileTypeRestrictedFrom: [ProfileType_PEnum.information, ProfileType_PEnum.other],
        profileCategoryRestrictedFrom: [ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat, ProfileCategory_PEnum.vehicle],
        children: [
            {
                name: "Address", 
                route: "maplocation",
                id: "locationMapLocationButton"
            }, 
            {
                name: "Directions (to)", 
                route: "directionsto",
                id: "locationDirectionsToButton", 
                restricted: [ProfileTier_DB_PEnum.Professional],
                profileCategoryRestrictedFrom: [ProfileCategory_PEnum.mobileSafari],
                profileTypeRestrictedFrom: [ProfileType_PEnum.destination]
            }, 
            {
                name: "Directions (from)", 
                route: "directionsfrom", 
                id: "locationDirectionsFromButton",
                restricted: [ProfileTier_DB_PEnum.Professional],
                profileCategoryRestrictedFrom: [ProfileCategory_PEnum.mobileSafari],
                profileTypeRestrictedFrom: [ProfileType_PEnum.destination]
            }, 
            {
                name: "Associations", 
                route: "Associations", 
                id: "locationAssociationsButton",
                profileTypeRestrictedFrom: [ProfileType_PEnum.information, ProfileType_PEnum.other],
                profileCategoryRestrictedFrom: [ProfileCategory_PEnum.dmo, ProfileCategory_PEnum.vehicle, ProfileCategory_PEnum.rail, ProfileCategory_PEnum.boat, ProfileCategory_PEnum.country]
            }
        ]
    },
    {
        name: "Media", 
        route: "media", 
        id: "mediaTab",
        children:  [
            {
                name: "Images", 
                route: "images",
                id: "mediaImagesButton"
            }, 
            {
                name: "Videos", 
                route: "videos", 
                id: "mediaVideosButton",
                restricted: [ProfileTier_DB_PEnum.Professional, ProfileTier_DB_PEnum.Lite]
            }, 
            {
                name: "Virtual tours", 
                route: "virtualtours", 
                id: "mediaVirtualToursButton",
                restricted: [ProfileTier_DB_PEnum.Professional]
            }, 
            {
                name: "Appearances", 
                route: "appearances",
                id: "mediaAppearancesButton", 
                restricted: [ProfileTier_DB_PEnum.Professional]
            }
        ]
    },
    {
        name: "Venue options", 
        route: "venueoptions",
        id: "venueOptionsTab",
        profileType: [ProfileType_PEnum.venues],
        children: [
            {
                name: "Venues", 
                route: "venues"
            }
        ]
    },
    {
        name: "Rooms and services", 
        route: "roomsandservices", 
        id: "roomsServicesTab",
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        profileCategoryRestrictedFrom: [ProfileCategory_PEnum.vehicle],
        children: [
            {
                name: "Sleeping arrangements", 
                route: "sleepingarrangements",
                id: "roomsServicesSleepingArrangementButton"
            }, 
            {
                id: "roomsServicesActivitiesButton",
                name: "Activities", 
                route: "activities", 
                restricted: [ProfileTier_DB_PEnum.Professional]
            }, 
            {
                id: "roomsServicesRestaurantButton",
                name: "Restaurants", 
                route: "restaurants", 
                restricted: [ProfileTier_DB_PEnum.Professional],
                profileCategoryRestrictedFrom: [ProfileCategory_PEnum.mobileSafari]
            }
        ]
    },
    {
        name: "Sleeping arrangements and services", 
        route: "sleepingarrangementsandservices", 
        profileType: [ProfileType_PEnum.accommodation],
        profileCategoryRestrictedFrom: getAllCategoriesExcept([ProfileCategory_PEnum.vehicle]),
        children: [
            {
                name: "Sleeping arrangements", 
                route: "sleepingarrangements"
            }, 
            {
                name: "Amenities", 
                route: "amenities"
            }
        ]
    },   
    {
        name: "Activity options", 
        route: "activityoptions",
        id: "activityOptionTab",
        profileType: [ProfileType_PEnum.activity, ProfileType_PEnum.attraction],
        children: [
            {
                name: "Options", 
                route: "options"
            }
        ]
    },
    {
        name: "Available spaces", 
        route: "avaliblespaces",
        id: "availableSpacesTab",
        profileType: [ProfileType_PEnum.restaurant, ProfileType_PEnum.infrastructure],
        children: [
            {
                name: "Spaces", 
                route: "spaces",
                profileTypeRestrictedFrom: [ProfileType_PEnum.infrastructure]
            },
            {
                name: "Spaces", 
                route: "infrastructurespaces",
                profileTypeRestrictedFrom: [ProfileType_PEnum.restaurant]
            }
        ]
    },   
    {
        name: "Available options", 
        route: "availableoptions",
        profileType: [ProfileType_PEnum.information, ProfileType_PEnum.other],
        children: [
            {
                name: "Options", 
                route: "options"
            }
        ]
    },   
    {
        name: "Attractions", 
        route: "attractions",
        profileType: [ProfileType_PEnum.destination],
        profileCategoryRestrictedFrom: [ProfileCategory_PEnum.dmo],
        children: [
            {
                name: "Attractions", 
                route: "attractions"
            },
            {
                name: "Activities", 
                route: "activities", 
                restricted: [ProfileTier_DB_PEnum.Professional]
            }, 
            {
                name: "Stays", 
                route: "stays", 
                restricted: [ProfileTier_DB_PEnum.Professional]
            }, 
            {
                name: "Destinations", 
                route: "destinations", 
                restricted: [ProfileTier_DB_PEnum.Professional]
            }
        ]
    },
    {
        name: "Places to explore", 
        route: "attractions",
        profileType: [ProfileType_PEnum.destination],
        profileCategoryRestrictedFrom: [ProfileCategory_PEnum.country, ProfileCategory_PEnum.area, ProfileCategory_PEnum.other],
        children: [
            {
                name: "Attractions", 
                route: "attractions"
            },
            {
                name: "Activities", 
                route: "dmo/activities", 
                restricted: [ProfileTier_DB_PEnum.Professional]
            }, 
            {
                name: "Stays", 
                route: "dmo/stays", 
                restricted: [ProfileTier_DB_PEnum.Professional]
            }, 
            {
                name: "Destinations", 
                route: "dmo/destinations", 
                restricted: [ProfileTier_DB_PEnum.Professional]
            }
        ]
    },
    {
        name: "Other", 
        route: "other",
        id: "otherTab", 
        children: [
            {
                name: "Documents", 
                route: "documents",
                id: "otherDocumentsButton"
            }, 
            {
                name: "Rates", 
                route: "rates", 
                id: "otherRatesButton",
                restricted: [ProfileTier_DB_PEnum.Professional],
                profileTypeRestrictedFrom: [ProfileType_PEnum.information, ProfileType_PEnum.restaurant, ProfileType_PEnum.infrastructure, ProfileType_PEnum.destination, ProfileType_PEnum.other]
            }, 
            {
                name: "Itineraries & packages", 
                route: "itineraries",
                id: "otherItinerariesPackagesButton",
                profileCategoryRestrictedFrom: getAllCategoriesExcept([
                    ProfileCategory_PEnum.rail,
                    ProfileCategory_PEnum.boat,
                    ProfileCategory_PEnum.vehicle,
                    ProfileCategory_PEnum.mobileSafari
                ]),
                profileTypeRestrictedFrom: [ProfileType_PEnum.information, ProfileType_PEnum.infrastructure, ProfileType_PEnum.destination, ProfileType_PEnum.other]
            },
            {
                name: "Packages", 
                route: "packages",
                id: "otherPackagesButton",
                restricted: [ProfileTier_DB_PEnum.Professional],
                profileCategoryRestrictedFrom: getAllCategoriesExcept([...venueCategoryList])
            },  
            {
                name: "Scheduled departures", 
                route: "scheduled-departures",
                id: "otherScheduledDeparturesButton",
                profileCategoryRestrictedFrom: getAllCategoriesExcept([
                    ProfileCategory_PEnum.rail,
                    ProfileCategory_PEnum.boat,
                    ProfileCategory_PEnum.vehicle,
                    ProfileCategory_PEnum.mobileSafari
                ]),
                restricted: [ProfileTier_DB_PEnum.Professional],
                profileTypeRestrictedFrom: [ProfileType_PEnum.information, ProfileType_PEnum.infrastructure, ProfileType_PEnum.destination, ProfileType_PEnum.other]
            }, 
            {
                name: "Integrations", 
                route: "integrations",
                id: "otherIntegrationButton",
                profileTypeRestrictedFrom: [ProfileType_PEnum.information, ProfileType_PEnum.destination, ProfileType_PEnum.other]
            }
        ]
    }
]

const otherCategory = { name: "Other", icon: "", description: "", value: ProfileCategory_PEnum.other }

export const accommodationCategoryArray = [
    {name: "Hotel", icon :"", description: "", value: ProfileCategory_PEnum.hotel, cardId: "categoryHotelChoiceCard"},
    {name: "Guest House", icon :"", description: "", value: ProfileCategory_PEnum.guestHouse, cardId: "categoryGuestHouseChoiceCard"},
    {name: "Short-term Rental", icon :"", description: "", value: ProfileCategory_PEnum.shortTermRental, cardId: "categoryShortTermRentalChoiceCard"},
    {name: "Villa", icon :"", description: "", value: ProfileCategory_PEnum.villa, cardId:"categoryVillaChoiceCard"},
    {name: "Lodge", icon :"", description: "", value: ProfileCategory_PEnum.lodge, cardId:"categoryLodgeChoiceCard"},
    {name: "Motel", icon :"", description: "", value: ProfileCategory_PEnum.motel, cardId:"categoryMotelChoiceCard"},
    {name: "Rail", icon :"", description: "", value: ProfileCategory_PEnum.rail, cardId:"categoryRailChoiceCard"},
    {name: "Boat", icon :"", description: "", value: ProfileCategory_PEnum.boat, cardId:"categoryBoatChoiceCard"},
    {name: "Mobile Safari", icon :"", description: "", value: ProfileCategory_PEnum.mobileSafari, cardId:"categoryMobileSafariChoiceCard"},
    {name: "Camp / Seasonal Camps", icon :"", description: "", value: ProfileCategory_PEnum.camp, cardId:"categoryCampChoiceCard"},
    {name: "Vehicle", icon :"", description: "", value: ProfileCategory_PEnum.vehicle, cardId:"categoryVehicleChoiceCard"},
    {...otherCategory, cardId:"categoryOtherChoiceCard"}
]

export const activityCategoryArray = [
    {name: "Adventure", icon :"", description: "", value: ProfileCategory_PEnum.adventure, cardId:"categoryThingsToDoAdventureChoiceCard"},
    {name: "Leisure", icon :"", description: "", value: ProfileCategory_PEnum.leisure, cardId:"categoryThingsToDoLeisureChoiceCard"},
    {name: "Outdoor", icon :"", description: "", value: ProfileCategory_PEnum.outdoor, cardId:"categoryThingsToDoOutdoorChoiceCard"},
    {name: "Sport", icon :"", description: "", value: ProfileCategory_PEnum.sport, cardId:"categoryThingsToDoSportChoiceCard"},
    {name: "Watersport", icon :"", description: "", value: ProfileCategory_PEnum.waterSport, cardId:"categoryThingsToDoWatersportChoiceCard"},
    {...otherCategory, cardId:"categoryThingsToDoOtherChoiceCard"}
]

export const restaurantCategoryArray = [
    {name: "African", icon :"", description: "", value: ProfileCategory_PEnum.african, cardId:"categoryFoodAfricanChoiceCard"},
    {name: "Asian", icon :"", description: "", value: ProfileCategory_PEnum.asian, cardId:"categoryFoodAsianSpiceChoiceCard"},
    {name: "Contemporary", icon :"", description: "", value: ProfileCategory_PEnum.contemporary, cardId:"categoryFoodContemporaryChoiceCard"},
    {name: "Deli", icon :"", description: "", value: ProfileCategory_PEnum.deli, cardId:"categoryFoodDeliChoiceCard"},
    {name: "Fast Foods", icon :"", description: "", value: ProfileCategory_PEnum.fastFoods, cardId:"categoryFoodFastFoodsChoiceCard"},
    {name: "Fine Dining", icon :"", description: "", value: ProfileCategory_PEnum.fineDining, cardId:"categoryFoodFineDiningChoiceCard"},
    {name: "Fusion", icon :"", description: "", value: ProfileCategory_PEnum.fusion, cardId:"categoryFoodFusionChoiceCard"},
    {name: "Grill", icon :"", description: "", value: ProfileCategory_PEnum.grill, cardId:"categoryFoodGrillChoiceCard"},
    {name: "Indian", icon :"", description: "", value: ProfileCategory_PEnum.indian, cardId:"categoryFoodIndianChoiceCard"},
    {name: "Local", icon :"", description: "", value: ProfileCategory_PEnum.local, cardId:"categoryFoodLocalChoiceCard"},
    {name: "Portuguese", icon :"", description: "", value: ProfileCategory_PEnum.portuguese, cardId:"categoryFoodPortugueseChoiceCard"},
    {name: "Seafood", icon :"", description: "", value: ProfileCategory_PEnum.seafood, cardId:"categoryFoodSeafoodChoiceCard"},
    {name: "Sushi", icon :"", description: "", value: ProfileCategory_PEnum.sushi, cardId:"categoryFoodSushiChoiceCard"},
    {...otherCategory, cardId:"categoryFoodOtherChoiceCard"}
]

export const attractionsCategoryArray = [
    {name: "Entertainment", icon :"", description: "", value: ProfileCategory_PEnum.entertainment, cardId:"categoryAttractionsEntertainmentChoiceCard"},
    {name: "History & Culture", icon :"", description: "", value: ProfileCategory_PEnum.historyCulture, cardId:"categoryAttractionsHistoryCultureChoiceCard"},
    {name: "Leisure", icon :"", description: "", value: ProfileCategory_PEnum.leisure, cardId:"categoryAttractionsHistoryCultureChoiceCard"},
    {name: "Nature", icon :"", description: "", value: ProfileCategory_PEnum.nature, cardId:"categoryAttractionsNatureChoiceCard"},
    {name: "Shopping", icon :"", description: "", value: ProfileCategory_PEnum.shopping, cardId:"categoryAttractionsShoppingChoiceCard"},
    {name: "Wildlife", icon :"", description: "", value: ProfileCategory_PEnum.wildlife, cardId:"categoryAttractionsWildlifeChoiceCard"},
    {...otherCategory, cardId:"categoryAttractionsOtherChoiceCard"}
]

export const informationServicesCategoryArray = [
    {name: "Information", icon :"", description: "", value: ProfileCategory_PEnum.information, cardId:"categoryServicesInformationInformationChoiceCard"},
    {name: "Meet and Greet", icon :"", description: "", value: ProfileCategory_PEnum.meetAndGreet, cardId:"categoryServicesInformationMeetGreetChoiceCard"},
    {name: "Person", icon :"", description: "", value: ProfileCategory_PEnum.person, cardId:"categoryServicesInformationPersonChoiceCard"},
    {...otherCategory, cardId:"categoryServicesInformationOtherChoiceCard"}
]

export const infrastructureCategoryArray = [
    {name: "International airport", icon :"", description: "", value: ProfileCategory_PEnum.internationalAirport, cardId:"categoryInfrastructureInternationalAirportChoiceCard"},
    {name: "Airport", icon :"", description: "", value: ProfileCategory_PEnum.airport, cardId:"categoryInfrastructureAirportChoiceCard"},
    {name: "Airfield", icon :"", description: "", value: ProfileCategory_PEnum.airField, cardId:"categoryInfrastructureAirfieldChoiceCard"},
    {name: "Harbour", icon :"", description: "", value: ProfileCategory_PEnum.harbour, cardId:"categoryInfrastructureHarbourChoiceCard"},
    {name: "Train station", icon :"", description: "", value: ProfileCategory_PEnum.trainStation, cardId:"categoryInfrastructureTrainStationChoiceCard"},
    {name: "Car Rental", icon :"", description: "", value: ProfileCategory_PEnum.carRental, cardId:"categoryInfrastructureCarRentalChoiceCard"},
    {...otherCategory, cardId:"categoryFoodOtherChoiceCard"}
]

export const destinationCategoryArray = [
    {name: "Area", icon :"", description: "", value: ProfileCategory_PEnum.area, cardId:"categoryDestinationAreaChoiceCard"},
    {name: "DMO", icon :"", description: "", value: ProfileCategory_PEnum.dmo, cardId:"categoryDestinationDMOChoiceCard", disableCreate: true },
    {name: "Country", icon :"", description: "", value: ProfileCategory_PEnum.country, cardId:"categoryDestinationCountryChoiceCard", disableCreate: true },
    {name: "Other", icon :"", description: "", value: ProfileCategory_PEnum.other, cardId:"categoryDestinationOtherStationChoiceCard"}
]

export const venuesCategoryArray = [
    {name: "Bed & Breakfast", icon :"", description: "", value: ProfileCategory_PEnum.bedBreakfast, cardId:"categoryVenuesBedAndBreakfastChoiceCard"},
    {name: "Corporate facility", icon :"", description: "", value: ProfileCategory_PEnum.corporateFacility, cardId:"categoryVenuesCorporateFacilityChoiceCard"},
    {name: "Expo centre", icon :"", description: "", value: ProfileCategory_PEnum.expoCentre, cardId:"categoryVenuesExpoCentreChoiceCard"},
    {name: "Guest houses & lodges", icon :"", description: "", value: ProfileCategory_PEnum.guestHousesLodges, cardId:"categoryVenuesGuestHousesLodgesChoiceCard"},
    {name: "Tented or outdoor camp", icon :"", description: "", value: ProfileCategory_PEnum.tentedOutdoorCamp, cardId:"categoryVenuesTentedOutdoorCampChoiceCard"},
    {name: "Other", icon :"", description: "", value: ProfileCategory_PEnum.other, cardId:"categoryVenuesOtherChoiceCard"}
]

export const otherCategoryArray = [{name: "Other", icon :"", description: "", value: ProfileCategory_PEnum.other}]

export const iBrochureLanguagesMap = [
    { name: "English", code: "en", isMachine: false},
    { name: "Catalan", code: "ca", isMachine: true},
    { name: "Chinese (Simplified)", code: "zh", isMachine: false},
    { name: "Chinese (Traditional)", code: "zh-TW", isMachine: false},
    { name: "Czech", code: "cs", isMachine: false},
    { name: "Danish", code: "da", isMachine: true},
    { name: "Dutch", code: "nl", isMachine: true},
    { name: "Finnish", code: "fi", isMachine: false},
    { name: "French", code: "fr", isMachine: true},
    { name: "German", code: "de", isMachine: true},
    { name: "Italian", code: "it", isMachine: true},
    { name: "Norwegian", code: "no", isMachine: true},
    { name: "Polish", code: "pl", isMachine: true},
    { name: "Portuguese", code: "pt", isMachine: true},
    { name: "Russian", code: "ru", isMachine: false},
    { name: "Spanish", code: "es", isMachine: true},
    { name: "Swedish", code: "sv", isMachine: true}
]

export const RoomConfiguration = [
    "Select configuration",
    "Single",
    "Double",
    "Twin",
    "Triple",
    "Quad/Family",
    "Queen",
    "King",
    "Suite",
    "Studio",
    "Connecting",
    "Murphy",
    "Accessible"
]

export const MIN_IMAGES = 6

export const MIN_IMAGE_MEGA_PIXELS = 2

export const BOOTSTRAP_GRID_SYSTEM_MAX_COLUMNS = 12

export const getMaxImages = (profileTier, isWetuOwned) => {
    if (isWetuOwned) {
        return 60
    }
//Image limits 
  //Basic=6 
  //Lite=30 
  //Professional=60 
    return profileTier === ProfileTier_DB_PEnum.Core ? 6 : profileTier === ProfileTier_DB_PEnum.Lite ? 30  : 60
}

export const accounts = {
    FACEBOOK: 'Facebook',
    TWITTER: 'Twitter',
    INSTAGRAM: 'Instagram',
    PINTEREST: 'Pinterest'
}

export const contactDetails = {
    MARKETING: 'Marketing',
    SALES: 'Sales',
    ACCOUNTS: 'Accounts'
}

export const inclusionTypes = {
    select: 'Select...',
    optional: 'Optional',
    included: 'Included',
    extra: 'Extra'
}

export const getDefaultProfileItemType = (profileItemTypes) => {
    switch (profileItemTypes[0]) {
        case ProfileItemType_PEnum.restaurant:
            return ProfileItemType_PEnum.restaurant
        case ProfileItemType_PEnum.activity:
            return ProfileItemType_PEnum.activity
        case ProfileItemType_PEnum.attraction:
            return ProfileItemType_PEnum.attraction
        case ProfileItemType_PEnum.option:
            return ProfileItemType_PEnum.option
        case ProfileItemType_PEnum.itineraries:
            return ProfileItemType_PEnum.itineraries
        case ProfileItemType_PEnum.amenity:
            return ProfileItemType_PEnum.amenity   
        case ProfileItemType_PEnum.spaces:
            return ProfileItemType_PEnum.spaces
        case ProfileItemType_PEnum.venue:
            return ProfileItemType_PEnum.venue
        case ProfileItemType_PEnum.stays:
            return ProfileItemType_PEnum.stays
        case ProfileItemType_PEnum.destinations:
            return ProfileItemType_PEnum.destinations
        case ProfileItemType_PEnum.package:
            return ProfileItemType_PEnum.package
        default:
            return ProfileItemType_PEnum.room
    }
}

export const GDSCodes = {
    AMADEUS: 'Amadeus',
    GALILEO: 'Galileo',
    PEGASUS: 'Pegasus',
    SABRE: 'Sabre',
    TRAVELPORT: 'Travelport',
    WORLDSPAN: 'Worldspan'
}

export const gdsCodesProperties = {
    amadeus: 'Amadeus',
    galileo: 'Galileo',
    pegasus: 'Pegasus',
    sabre: 'Sabre',
    travelport: 'Travelport',
    worldspan: 'Worldspan'
}

export const eventTypes = [
    {eventName: "Banquet", eventTypeIcon: banquetEventIcon, propertyName: "maxCapacityForBanquet"},
    {eventName: "Boardroom", eventTypeIcon: boardroomEventIcon, propertyName: "maxCapacityForBoardroom"},
    {eventName: "Reception", eventTypeIcon: resceptionEventIcon, propertyName: "maxCapacityForReception"},
    {eventName: "School room", eventTypeIcon: schoolRoomEventIcon, propertyName: "maxCapacityForSchoolRoom"},
    {eventName: "Theatre", eventTypeIcon: theatreEventIcon, propertyName: "maxCapacityForTheater"},
    {eventName: "U-Shaped", eventTypeIcon: uShapedEventIcon, propertyName: "maxCapacityForUShaped"}
] 

export const currencies = [
    {code: 'AFN', name: 'Afghan Afghani', country: 'Afghanistan'},
    {code: 'DZD', name: 'Algerian Dinar', country: 'Algeria'},
    {code: 'ARS', name: 'Argentine Peso', country: 'Argentina'},
    {code: 'AMD', name: 'Armenian Dram', country: 'Armenia'},
    {code: 'AWG', name: 'Aruban Florin', country: 'Aruba'},
    {code: 'AUD', name: 'Australian Dollar', country: 'Australia'},
    {code: 'AZN', name: 'Azerbaijanian Manat', country: 'Azerbaijan'},
    {code: 'BSD', name: 'Bahamian Dollar', country: 'Bahamas'},
    {code: 'BHD', name: 'Bahraini Dinar', country: 'Bahrain'},
    {code: 'THB', name: 'Thai Baht', country: 'Thailand'},
    {code: 'PAB', name: 'Panamanian Balboa', country: 'Panama'},
    {code: 'BBD', name: 'Barbados Dollar', country: 'Barbados'},
    {code: 'BYN', name: 'Belarussian Ruble', country: 'Belarus'},
    {code: 'BZD', name: 'Belize Dollar', country: 'Belize'},
    {code: 'BMD', name: 'Bermudian Dollar', country: 'Bermuda'},
    {code: 'VES', name: 'Bolivar Soberano', country: 'Bolivar'},
    {code: 'BOB', name: 'Bolivian Boliviano', country: 'Bolivia'},
    {code: 'BRL', name: 'Brazilian Real', country: 'Brazil'},
    {code: 'BND', name: 'Brunei Dollar', country: 'Brunei'},
    {code: 'BGN', name: 'Bulgarian Lev', country: 'Bulgaria'},
    {code: 'BIF', name: 'Burundi Franc', country: 'Burundi'},
    {code: 'CAD', name: 'Canadian Dollar', country: 'Canada'},
    {code: 'CVE', name: 'Cape Verde Escudo', country: 'Cape Verde'},
    {code: 'KYD', name: 'Cayman Islands Dollar', country: 'Cayman Islands'},
    {code: 'GHS', name: 'Ghanaian Cedi', country: 'Ghana'},
    {code: 'XOF', name: 'CFA Franc BCEAO', country: 'Benin, Burkina Faso, Guinea-Bissau, Ivory Coast, Mali, Niger, Senegal, Togo'},
    {code: 'XAF', name: 'CFA Franc BEAC', country: 'Cameroon, Central African Republic, Chad, Republic of the Congo, Equatorial Guinea, Gabon'},
    {code: 'XPF', name: 'CFP Franc', country: 'French Polynesia, New Caledonia, Wallis and Futuna'},
    {code: 'CLP', name: 'Chilean Peso', country: 'Chile'},
    {code: 'COP', name: 'Colombian Peso', country: 'Colombia'},
    {code: 'KMF', name: 'Comoro Franc', country: 'Comoros'},
    {code: 'CDF', name: 'Congolese France', country: 'Democratic Republic of the Congo'},
    {code: 'BAM', name: 'Convertible Marks', country: 'Bosnia and Herzegovina'},
    {code: 'NIO', name: 'Cordoba Oro', country: 'Nicaragua'},
    {code: 'CRC', name: 'Costa Rican Colon', country: 'Costa Rica'},
    {code: 'HRK', name: 'Croatian Kuna', country: 'Croatia'},
    {code: 'CZK', name: 'Czech Koruna', country: 'Czech Republic'},
    {code: 'GMD', name: 'Gambian Dalasi', country: 'Gambia'},
    {code: 'DKK', name: 'Danish Krone', country: 'Denmark'},
    {code: 'MKD', name: 'Macedonian Denar', country: 'North Macedonia'},
    {code: 'DJF', name: 'Djibouti Franc', country: 'Djibouti'},
    {code: 'STD', name: 'São Tomé and Príncipe Dobra', country: 'São Tomé and Príncipe'},
    {code: 'DOP', name: 'Dominican Peso', country: 'Dominican Republic'},
    {code: 'VND', name: 'Vietnamese Dong', country: 'Vietnam'},
    {code: 'XCD', name: 'East Caribbean Dollar', country: 'East Caribbean'},
    {code: 'EGP', name: 'Egyptian Pound', country: 'Egypt'},
    {code: 'SVC', name: 'El Salvador Colon', country: 'El Salvador'},
    {code: 'ETB', name: 'Ethiopian Birr', country: 'Ethiopia'},
    {code: 'EUR', name: 'European Euro', country: 'Eurozone'},
    {code: 'FKP', name: 'Falkland Islands Pound', country: 'Falkland Islands'},
    {code: 'FJD', name: 'Fiji Dollar', country: 'Fiji'},
    {code: 'HUF', name: 'Hungarian Forint', country: 'Hungary'},
    {code: 'GIP', name: 'Gibraltar Pound', country: 'Gibraltar'},
    {code: 'HTG', name: 'Gourde', country: 'Haiti'},
    {code: 'PYG', name: 'Paraguayan Guaraní', country: 'Paraguay'},
    {code: 'GNF', name: 'Guinea Franc', country: 'Guinea'},
    {code: 'GYD', name: 'Guyana Dollar', country: 'Guyana'},
    {code: 'HKD', name: 'Hong Kong Dollar', country: 'Hong Kong'},
    {code: 'UAH', name: 'Ukrainian Hryvnia', country: 'Ukraine'},
    {code: 'ISK', name: 'Iceland Krona', country: 'Iceland'},
    {code: 'INR', name: 'Indian Rupee', country: 'India'},
    {code: 'IRR', name: 'Iranian Rial', country: 'Iran'},
    {code: 'IQD', name: 'Iraqi Dinar', country: 'Iraq'},
    {code: 'JMD', name: 'Jamaican Dollar', country: 'Jamaica'},
    {code: 'JOD', name: 'Jordanian Dinar', country: 'Jordan'},
    {code: 'KES', name: 'Kenyan Shilling', country: 'Kenya'},
    {code: 'PGK', name: 'Papua New Guinean Kina', country: 'Papua New Guinea'},
    {code: 'LAK', name: 'Lao Kip', country: 'Laos'},
    {code: 'EEK', name: 'Estonian Kroon', country: 'Estonia'},
    {code: 'KWD', name: 'Kuwaiti Dinar', country: 'Kuwait'},
    {code: 'MWK', name: 'Malawian Kwacha', country: 'Malawi'},
    {code: 'AOA', name: 'Kwanza', country: 'Angola'},
    {code: 'MMK', name: 'Myanmar Kyat', country: 'Myanmar'},
    {code: 'GEL', name: 'Georgian Lari', country: 'Georgia'},
    {code: 'LVL', name: 'Latvian Lats', country: 'Latvia'},
    {code: 'LBP', name: 'Lebanese Pound', country: 'Lebanon'},
    {code: 'ALL', name: 'Albanian Lek', country: 'Albania'},
    {code: 'HNL', name: 'Honduran Lempira', country: 'Honduras'},
    {code: 'SLL', name: 'Sierra Leonean Leone', country: 'Sierra Leone'},
    {code: 'LRD', name: 'Liberian Dollar', country: 'Liberia'},
    {code: 'LYD', name: 'Libyan Dinar', country: 'Libya'},
    {code: 'SZL', name: 'Swazi Lilangeni', country: 'Eswatini'},
    {code: 'LTL', name: 'Lithuanian Litas', country: 'Lithuania'},
    {code: 'LSL', name: 'Lesotho Loti', country: 'Lesotho'},
    {code: 'MGA', name: 'Malagasy Ariary', country: 'Madagascar'},
    {code: 'MYR', name: 'Malaysian Ringgit', country: 'Malaysia'},
    {code: 'TMT', name: 'Azerbaijani Manat', country: 'Turkmenistan'},
    {code: 'MUR', name: 'Mauritius Rupee', country: 'Mauritius Rupee'},
    {code: 'MZN', name: 'Mozambican Metical', country: 'Mozambique'},
    {code: 'MXN', name: 'Mexican Peso', country: 'Mexico'},
    {code: 'MDL', name: 'Moldovan Leu', country: 'Moldolva'},
    {code: 'MAD', name: 'Moroccan Dirham', country: 'Morocco'},
    {code: 'NGN', name: 'Nigerian Naira', country: 'Nigeria'},
    {code: 'ERN', name: 'Eritrean Nakfa', country: 'Eritrea'},
    {code: 'NAD', name: 'Namibia Dollar', country: 'Namibia'},
    {code: 'NPR', name: 'Nepalese Rupee', country: 'Nepal'},
    {code: 'ANG', name: 'Netherlands Antillian Guilder', country: 'Curaçao and Sint Maarten'},
    {code: 'ILS', name: 'New Israeli Sheqel', country: 'Israel'},
    {code: 'RON', name: 'Romanian Leu', country: 'Romania'},
    {code: 'TWD', name: 'New Taiwan Dollar', country: 'Republic of China (Taiwan)'},
    {code: 'NZD', name: 'New Zealand Dollar', country: 'New Zealand'},
    {code: 'BTN', name: 'Bhutanese Ngultrum', country: 'Kingdom of Bhutan'},
    {code: 'KPW', name: 'North Korean Won', country: 'North Korea'},
    {code: 'NOK', name: 'Norwegian Krone', country: 'Norway'},
    {code: 'PEN', name: 'Peruvian Sol', country: 'Peru'},
    {code: 'MRO', name: 'Mauritanian Ouguiya', country: 'Mauritania'},
    {code: 'TOP', name: 'Tongan Paʻanga', country: 'Tonga'},
    {code: 'PKR', name: 'Pakistan Rupee', country: 'Pakistan'},
    {code: 'MOP', name: 'Macanese Pataca', country: 'Macau'},
    {code: 'UYU', name: 'Peso Uruguayo', country: 'Uruguay'},
    {code: 'PHP', name: 'Philippine Peso', country: 'Philippines'},
    {code: 'GBP', name: 'Pound Sterling', country: 'United Kingdom, Jersey, Guernsey, the Isle of Man, South Georgia and the South Sandwich Islands, the British Antarctic Territory, Tristan da Cunha'},
    {code: 'BWP', name: 'Botswana Pula', country: 'Botswana'},
    {code: 'QAR', name: 'Qatari Rial', country: 'State of Qatar'},
    {code: 'GTQ', name: 'Guatemalan Quetzal', country: 'Quetzal'},
    {code: 'ZAR', name: 'South African Rand', country: 'South Africa'},
    {code: 'OMR', name: 'Rial Omani', country: 'Oman'},
    {code: 'KHR', name: 'Cambodian Riel', country: 'Cambodia'},
    {code: 'MVR', name: 'Maldivian Rufiyaa', country: 'Maldives'},
    {code: 'IDR', name: 'Indonesian Rupiah', country: 'Rufiyaa'},
    {code: 'RUB', name: 'Russian Ruble', country: 'Russia'},
    {code: 'RWF', name: 'Rwanda Franc', country: 'Rwanda'},
    {code: 'SHP', name: 'Saint Helena Pound', country: 'Saint Helena and Ascension'},
    {code: 'SAR', name: 'Saudi Riyal', country: 'Saudi Arabia'},
    {code: 'RSD', name: 'Serbian Dinar', country: 'Serbia'},
    {code: 'SCR', name: 'Seychelles Rupee', country: 'Seychelles'},
    {code: 'SGD', name: 'Singapore Dollar', country: 'Singapore'},
    {code: 'SBD', name: 'Solomon Islans Dollar', country: 'Solomon Islands'},
    {code: 'KGS', name: 'Kyrgyzstani Som', country: 'Kyrgyz Republic'},
    {code: 'SOS', name: 'Somali Shilling', country: 'Somalia'},
    {code: 'TJS', name: 'Tajikistani Somoni', country: 'Tajikistan'},
    {code: 'LKR', name: 'Sri Lanka Rupee', country: 'Sri Lanka'},
    {code: 'SDG', name: 'Sudanese Pound', country: 'Republic of the Sudan'},
    {code: 'SRD', name: 'Surinamese Dollar', country: 'Republic of the Sudan'},
    {code: 'SEK', name: 'Swedish Krona', country: 'Sweden'},
    {code: 'CHF', name: 'Swiss Franc', country: 'Switzerland and Liechtenstein'},
    {code: 'SYP', name: 'Syrian Pound', country: 'Syria'},
    {code: 'BDT', name: 'Bangladeshi Taka', country: `People's Republic of Bangladesh`},
    {code: 'WST', name: 'Samoan Tala', country: 'Samoa'},
    {code: 'TZS', name: 'Tanzanian Shilling', country: 'Tanzania'},
    {code: 'KZT', name: 'Kazakhstani Tenge', country: 'Kazakhstan'},
    {code: 'TTD', name: 'Trinidad and Tobago Dollar', country: 'Trinidad and Tobago'},
    {code: 'MNT', name: 'Mongolian Tugrik', country: 'Mongolia'},
    {code: 'TND', name: 'Tunisian Dinar', country: 'Tunisia'},
    {code: 'TRY', name: 'Turkish Lira', country: 'Turkey'},
    {code: 'AED', name: 'UAE Dirham', country: 'United Arab Emirates'},
    {code: 'UGX', name: 'Uganda Shilling', country: 'Uganda'},
    {code: 'CLF', name: 'Unidad de Fomento', country: 'Chile'},
    {code: 'USD', name: 'US Dollar', country: 'United States'},
    {code: 'UZS', name: 'Uzbekistan Sum', country: 'Uzbekistan'},
    {code: 'VUV', name: 'Vanuatu Vatu', country: 'Vanuatu'},
    {code: 'KRW', name: 'South Korean Won', country: 'South Korea'},
    {code: 'YER', name: 'Yemeni Rial', country: 'Yemen'},
    {code: 'JPY', name: 'Japanese Yen', country: 'Japan'},
    {code: 'CNY', name: 'Yuan Renminbi', country: `People's Republic of China`},
    {code: 'ZMW', name: 'Zambian Kwacha', country: 'Zambia'},
    {code: 'ZWL', name: 'Zimbabwe Dollar', country: 'Zimbabwe'},
    {code: 'PLN', name: 'Polish Zloty', country: 'Poland'}
]

export const discountPercentages = [10, 15, 20, 25, 30]

export const pricingStructures = [
    { name: 'Single (S)', key: PricingConfigType_PEnum.single, profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues, ProfileType_PEnum.activity, ProfileType_PEnum.attraction]}, 
    { name: 'Per Person (PP)', key: PricingConfigType_PEnum.perPerson, profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.activity, ProfileType_PEnum.attraction, ProfileType_PEnum.venues] }, 
    { name: 'Per Person Sharing (PPS)', key: PricingConfigType_PEnum.perPersonSharing, profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues, ProfileType_PEnum.activity, ProfileType_PEnum.attraction] },
    { name: 'Per Person Per Night Sharing (PPPNS)', key: PricingConfigType_PEnum.perPersonPerNightSharing, profileTypes: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues, ProfileType_PEnum.activity, ProfileType_PEnum.attraction]},
    { name: 'Per Room / Suite', key: PricingConfigType_PEnum.perRoomSuite, profileTypes: [ProfileType_PEnum.accommodation] }, 
    { name: 'Per Unit / Villa', key: PricingConfigType_PEnum.perUnitVilla, profileTypes: [ProfileType_PEnum.accommodation] }
]

export const ratePricingOptionGridSequences = [
    //For grid config sequence, so config items appear in the order UX want not the order they were created.
    { key: PricingConfigType_PEnum.single, sequence: 0 },
    { key: PricingConfigType_PEnum.perPerson, sequence: 1 },
    { key: PricingConfigType_PEnum.perPersonSharing, sequence: 2 },
    { key: PricingConfigType_PEnum.perPersonPerNightSharing, sequence: 3 },
    { key: PricingConfigType_PEnum.perRoomSuite, sequence: 4 },
    { key: PricingConfigType_PEnum.perUnitVilla, sequence: 5 },
    { key: PricingConfigType_PEnum.pax, sequence: 6 },
    { key: PricingConfigType_PEnum.age, sequence: 7 }
]

export const conditionsOptions = [
    {title: 'Inclusion', key: 'Inclusion'}, 
    {title: 'Payment and Cancellation', key: 'PaymentCancellation'}, 
    {title: 'Child Policy', key: 'ChildPolicy'}, 
    {title: 'Terms and Conditions', key: 'TermsConditions'}
]

export const CONFIG = {
    CONSTS: {
        LATITUDE: {
            MIN: -90,
            MAX: 90
        },
        LONGITUDE: {
            MIN: -180,
            MAX: 180
        },
        CHECK_MACHINE_TRANS_COMPLETE_MILLIS: 15000
    }
}

export const coverImageLookupMap = [
    {
        type: ProfileItemType_PEnum.room,
        mediaType: MediaType_PEnum.roomsCoverImage,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        name: 'Rooms'
    },
    {
        type: ProfileItemType_PEnum.sleepingArrangement,
        mediaType: MediaType_PEnum.sleepingArrangementsCoverImage,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        name: 'Sleeping Arrangement'
    },
    {
        type: ProfileItemType_PEnum.suite,
        mediaType: MediaType_PEnum.suitesCoverImage,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        name: 'Suites'
    },
    {
        type:ProfileItemType_PEnum.tent,
        mediaType: MediaType_PEnum.tentsCoverImage,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        name: 'Tents'
    },
    {
        type:ProfileItemType_PEnum.unit,
        mediaType: MediaType_PEnum.unitsCoverImage,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        name: 'Units'
    },
    {
        type:ProfileItemType_PEnum.villa,
        mediaType: MediaType_PEnum.villasCoverImage,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        name: 'Villas'
    },
    {
        type:ProfileItemType_PEnum.dome,
        mediaType: MediaType_PEnum.domesCoverImage,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        name: 'Domes'
    },
    {
        type:ProfileItemType_PEnum.carriage,
        mediaType: MediaType_PEnum.carriagesCoverImage,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        name: 'Carriages'
    },
    {
        type:ProfileItemType_PEnum.cabin,
        mediaType: MediaType_PEnum.cabinsCoverImage,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        name: 'Cabins'
    },
    {
        type: ProfileItemType_PEnum.cabana,
        mediaType: MediaType_PEnum.cabanasCoverImage,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        name: 'Cabanas'
    },
    {
        type:ProfileItemType_PEnum.apartment,
        mediaType: MediaType_PEnum.apartmentsCoverImag,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.venues],
        name: 'Apartments'
    },
    {
        type:ProfileItemType_PEnum.restaurant,
        mediaType: MediaType_PEnum.restaurantsCoverImage,
        profileType: [ProfileType_PEnum.accommodation],
        name: 'Restaurants'
    },
    {
        type:ProfileItemType_PEnum.activity,
        mediaType: MediaType_PEnum.activitiesCoverImage,
        profileType: [
            ProfileType_PEnum.accommodation,
            ProfileType_PEnum.activity,
            ProfileType_PEnum.attraction,
            ProfileType_PEnum.destination
        ],
        name: 'Activity options'
    },
    {
        type: FeatureType_PEnum.facility,
        mediaType: MediaType_PEnum.facilitiesCoverImage,
        profileType: [ProfileType_PEnum.accommodation, ProfileType_PEnum.activity, ProfileType_PEnum.attraction],
        name: 'Facilities'
    },
    {
        type:ProfileItemType_PEnum.restaurant,
        mediaType: MediaType_PEnum.spacesCoverImage,
        profileType: [ProfileType_PEnum.restaurant],
        name: 'Available spaces'
    },
    {
        type:ProfileItemType_PEnum.spaces,
        mediaType: MediaType_PEnum.spacesCoverImage,
        profileType: [ProfileType_PEnum.infrastructure],
        name: 'Available spaces'
    },
    {
        type: ProfileItemType_PEnum.option,
        mediaType: MediaType_PEnum.activitiesCoverImage,
        profileType: [ProfileType_PEnum.information],
        name: 'Activity options'
    },
    {
        type: ProfileItemType_PEnum.option,
        mediaType: MediaType_PEnum.optionCoverImage,
        profileType: [ProfileType_PEnum.other],
        name: 'Available options'
    },
    {
        type:ProfileItemType_PEnum.amenity,
        mediaType: MediaType_PEnum.amenitiesCoverImage,
        profileType: [ProfileType_PEnum.accommodation],
        name: 'Amenities'
    },
    {
        type:ProfileItemType_PEnum.attraction,
        mediaType: MediaType_PEnum.attractionsCoverImage,
        profileType: [ProfileType_PEnum.destination],
        name: 'Attractions'
    },
    {
        type:ProfileItemType_PEnum.stays,
        mediaType: MediaType_PEnum.staysCoverImage,
        profileType: [ProfileType_PEnum.destination],
        name: 'Stays'
    },
    {
        type:ProfileItemType_PEnum.destinations,
        mediaType: MediaType_PEnum.destinationsCoverImage,
        profileType: [ProfileType_PEnum.destination],
        name: 'Destinations'
    },
    {
        type:ProfileItemType_PEnum.venue,
        mediaType: MediaType_PEnum.venuesCoverImage,
        profileType: [ProfileType_PEnum.venues],
        name: 'Venue options'
    }
]

export const wizardStepId = {
    //TODO: phase wizardNumberSteps out, it is incorrect and misleading. The step number is not fixed but dependent on profile type.
    AFFILIATE_STEP_NUMBER: 0,
    TYPE_STEP_NUMBER: 2,
    CATEGORY_STEP_NUMBER: 3,
    DESCRIPTION_STEP_NUMBER: 4,
    LOCATION_STEP_NUMBER: 5,
    DESTINATION_LINKING_STEP_NUMBER: 6,
    FACILITIES_STEP_NUMBER: 7,
    LANGUAGE_STEP_NUMBER: 8,
    INFORMATION_STEP_NUMBER: 9,
    IMAGE_STEP_NUMBER: 10,
    FINALISE_STEP_NUMBER: 11
}

export const filterFeaturesData = (data, filterType) => {
    //Recursively parses the featuresData tree structure, filtering out config items (tree leaves) whose restrictedTo array does not contain the specified filterType.
    //A config item is included if:
    //1. Its restrictedTo array contains filterType.
    //2. It has no restrictedTo property.
    //2. filterType parameter is not passed and is therefore null.
    if (Array.isArray(data)) {
        data = data.filter(x => (!x.restrictedTo || !x.restrictedTo.length) || !filterType || x.restrictedTo.some(y => y === filterType))
            .sort((a, b) => a.name.localeCompare(b.name))
    } else {
        for (const i in data) {
            data[i] = filterFeaturesData(data[i], filterType)
        }
    }
    return data
}

export const setRateGridDisplaySettings = (configItem) => {
    //Set the pricing config title for each grid row. And set gridSequence, so config items appear in the order UX want not the order they were created.
    const structureItem = pricingStructures.find(x => x.key === configItem.configType)
    const sequenceItem = ratePricingOptionGridSequences.find(x => x.key === configItem.configType)
    if (configItem.configType === PricingConfigType_PEnum.pax) {
        configItem.title = `Passengers: ${configItem.fromValue} ${configItem.toValue || configItem.isPP || configItem.isPPS ? '-' : ''} ${configItem.toValue} ${configItem.isPP ? 'PP' : configItem.isPPS ? 'PPS' : ''}`
    } else if (configItem.configType === PricingConfigType_PEnum.age) {
        configItem.title = `Child ages: ${configItem.fromValue} ${configItem.toValue ? '-' : ''} ${configItem.toValue}`
    } else {
        configItem.title = structureItem ? structureItem.name : ''
    }
    configItem.gridSequence = sequenceItem ? sequenceItem.sequence : 0
}

export const isAnyMtLanguages = (iBrochureLanguages) => {
    const arr = iBrochureLanguagesMap.filter(x => iBrochureLanguages.some(y => y === x.name))
    return arr?.some(z => z.isMachine)
}

export const wizardConstants = {
    INTEGRATIONS: 'Integrations',
    PROFILE_TYPE: 'Type',
    PROFILE_CATEGORY: 'Category',
    PROFILE_DESCRIPTION: 'Description',
    LOCATION: 'Location',
    DESTINATION: 'Destination',
    FACILITIES: 'Facilities',
    LANGUAGES: 'Languages',
    INFORMATION: 'Information',
    CAMP_INFORMATION: 'Camp Information',
    IMAGES: 'Images',
    FINALISE: 'Finalise',
    PUBLISH: 'Publish'
}

export const wizardNextButtonId = {
    AFFILIATE_NEXT_BUTTON_ID: 'affiliateNextbutton', //0
    TYPE_NEXT_BUTTON_ID: 'typeNextbutton', //1
    CATEGORY_NEXT_BUTTON_ID: 'categoryNextButton', //2
    DESCRIPTION_NEXT_BUTTON_ID: 'descriptionNextButton', //3 
    DESTINATION_NEXT_BUTTON_ID:'destinationNextButton', //4
    LOCATION_NEXT_BUTTON_ID:'locationNextButton', //4
    FACILITIES_NEXT_BUTTON_ID: 'facilitiesNextButton', // 5
    LANGUAGES_NEXT_BUTTON_ID: 'languagesNextButton', //6 
    INFORMATION_NEXT_BUTTON_ID: 'informationNextButton', //7
    IMAGES_NEXT_BUTTON_ID: 'imagesNextButton', //8
    FINALISE_PREVIOUS_BUTTON_ID: 'finalisePreviousButton' //9
}

export const maxFileSize = {
    IMAGE: 25 * 1024 * 1024, //25mb
    DOCUMENT: 25 * 1024 * 1024, //25mb
    VIDEO: 500 * 1024 * 1024 //500mb
}